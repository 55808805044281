.modern-experience-timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 10px;
}

.modern-experience-header { 
    color: var(--modern-primary-color);
    font-size: 3rem;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;  
    padding: 12px;
    margin: 0px;
}


.timeline-item {
    width: "100%";
    height: "100%";
    padding-right: 30px;

}

.custom-card {
    background-color: white;
}

.custom-title {
    color: black;
    font-size: 36px;
}

.custom-card-title {
    color: black;
    font-size: 36px;
}

.custom-card-detailed-text {
    color: black;
    font-size: 36px;
}