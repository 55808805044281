.typingTitle {
    width: fit-content;
    padding-top: 40px;
    text-align: left;
}

.typewriter h1 {
    font-size: 4rem;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;  
    padding: 12px;
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid var(--modern-primary-color); /* The typewriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(40, end),
      blink-caret .65s step-end infinite;
}

/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--modern-primary-color); }
}
