.overflow-bar {
  width: 500px;
  height: 102%;
  position: relative; /* z-index only works on positioned elements (position:absolute, position:relative, or position:fixed) */
  z-index: 2; /* Higher z-index means this element will be on top */
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.5);
}

.overflow-bar-mobile {
  height: 100%;
  position: relative; /* z-index only works on positioned elements (position:absolute, position:relative, or position:fixed) */
  z-index: 2; /* Higher z-index means this element will be on top */
}

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  transition: opacity 0.3s ease; /* Smooth transition for the opacity change */
}

.menu-container:hover {
  opacity: 0.7; /* Less opaque on hover */
}

.name-header {
  color: white;
  text-align: left;
  margin: 3px;
  font-size: 80px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;  
}

.name-divider {
  width: 80%;
  height: 2px;          /* Sets the height of the line */
  background-color: white; /* Sets the color of the line */
  margin: 60px 0;      /* Adds some space above and below the line */
}

.name-divider-mobile {
  width: 80%;
  height: 2px;          /* Sets the height of the line */
  background-color: white; /* Sets the color of the line */
  margin: 30px 0;      /* Adds some space above and below the line */
}
