/* Sidebar.css */
.sidebar {
    display: flex;
    width: 100px;
    background-color: #ececec;
    padding-bottom: 20px;
}

.sidebarMobile {
    display: flex;
    width: 100%;
    background-color: var(--primary-color); /* Example background color */
    padding-bottom: 10px;
}


.slide-in {
    display: flex;
    width: 0;
    transform: translateX(100%); /* Slide out to the right */
    opacity: 0; /* Make the element fully transparent */
    transition: transform 0.2s ease-in, opacity 0s ease-in; /* Smooth transition for sliding and fading out */
}

.hamburger-menu-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    width: 100%;
    height: 10%;
    transition: opacity 0.3s ease; /* Smooth transition for the opacity change */
}

.hamburger-menu-container-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    width: 20%;
    height: 140%;
    transition: opacity 0.3s ease; /* Smooth transition for the opacity change */
}

.hamburger-menu-container:hover {
    opacity: 0.7; /* Less opaque on hover */
}

.hamburger-menu-container-mobile:hover {
    opacity: 0.7; /* Less opaque on hover */
}

.download-icon {
    height: 50x;
    width: 50px;
    transition: transform 0.5s ease;
    transform: scale(1); /* Default state */
}

.download-icon:hover {
    transform: scale(1.7); /* Zoom in on hover */
}

.flex-spacer {
    flex-grow: 1; /* This makes the spacer grow */
}