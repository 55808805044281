.topBarNav {
    padding: 20px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 5;
}

.topBarNav.scrolled {
    background-color: black; /* New background color when scrolled */
    /* Add other styling for scrolled state if needed */
}


.navbar {
    width: 10%;
    color: white;
    padding: 10px;
}

.menu-button {
    padding: 12px;
    cursor: pointer;
    color: black;
}

.menu-button.scrolled {
    padding: 12px;
    cursor: pointer;
    color: white;
}

.menu-button {
    height: 1rem;
    position: relative;
    /* Other styling */
}
  
.menu-icon {
    font-size: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-out;
}


/* Show class to smoothly transition in */
.show {
    opacity: 1;
}
  
/* Hide class to smoothly transition out */
.hide {
    opacity: 0 !important; /* Add !important to ensure override */
}

.top-nav-menu-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-100%); /* Start off-screen */
    opacity: 0; /* Start as invisible */
    visibility: hidden; /* instead of display: none; */
    transition: visibility 0s linear 0.5s, opacity 0.5s ease, transform 0.5s ease; /* Add visibility to transition */
}

.top-nav-menu-container.show {
    transform: translateY(0); /* Bring into view */
    opacity: 1; /* Make fully visible */
    visibility: visible; /* instead of display: block; */
    transition: visibility 0s linear 0s, opacity 0.5s ease, transform 0.5s ease; /* Add visibility to transition */
}

.top-nav-menu-container.hide {
    transform: translateY(-100%);
    opacity: 0;
}

.menu-list {
    color: black;
    height: 100%;
    list-style: none;
    padding: 0;
}

.menu-list.scrolled {
    color: white;
}

.menu-list {
    li {
        cursor: pointer;
        padding: 10px;
        font-size: 1.7rem;
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 200;
        font-style: normal; 
    }
    
    li:hover {
        color: var(--modern-primary-color);
        font-size: 2rem;
    }
}

.resume-bar {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 6px;
    gap: 12px;
    text-decoration: none;
}

.resume-bar.scrolled {
    color: var(--modern-primary-color);
}

.resume-text {
    font-size: 1.3rem;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}

.resume-download-icon {
    width: 40px;
    transition: transform 0.5s ease;
    transform: scale(1); /* Default state */
}

.resume-download-icon:hover {
    transform: scale(1.7); /* Zoom in on hover */
}