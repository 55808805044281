.insights-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .insights-bar {
        flex-wrap: wrap;
    }
}