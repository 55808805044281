.btn-container {
    display: flex;
    justify-content: center;
    padding: 60px 0px;
}

.btn { 
    width: 100%;
    padding: 18px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    font-size: 0.8em;
    text-transform: uppercase;
    letter-spacing: .3rem;
    color: white;
    text-decoration: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: var(--modern-primary-color);
    cursor: pointer;
}

.secondary { 
    background-color: #151515;
}

.tertiary { 
    color: #151515;
    background-color: #c5c5c5;
}

.btn:hover {
    opacity: 0.7; /* Less opaque on hover */
}