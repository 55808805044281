.section-Container {}

.section-header {
    color: var(--modern-primary-color);
    font-size: 3rem;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;  
    padding: 12px;
    margin: 0px;
}

.section-header.dark {
    color: white;
}

.section-desc {
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    text-align: center;
}

.section-desc.dark {
    color: white;
}

.section-divider {
    width: 80%;
    height: 2px;          /* Sets the height of the line */
    background-color: black; /* Sets the color of the line */
    margin: 30px 0;      /* Adds some space above and below the line */
}

.section-divider.dark {
    background-color: white;
}
