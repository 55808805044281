.titleCard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.positionTitle {
    color: var(--modern-primary-color);
    font-size: 1.6rem;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
    letter-spacing: 0em; /* Adjust as needed */

}