.blockyTemplate {
    background-color: var(--primary-color); /* Example background color */
    box-sizing: border-box;
}

.sectionOne {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 100px;
}

.sectionOneMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.titleCardMobile {
    position: relative;
    width: 100%;
    box-shadow: 0px 7px 20px 10px rgba(0, 0, 0, 0.5);
}